.container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--primary);
  color: var(--background);
}

.copyrightSection {
  padding: 12px 0;
}
