.container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--background);
  align-items: center;
  box-sizing: border-box;
  
}

.containerMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.containerInner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* max-width: 800px; */
  padding: 10px 32px;
  align-items: center;
  box-sizing: border-box;
}

.logo {
  /* Here for styling left hand side logo */
  width: 80px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

.nav {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.navLinkDesktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  padding-top: 10px;
  font-weight: bold;
}

.navLinkDesktopCurrent {
  width: 30px;
  height: 2px;
  background-color: var(--feature);
  margin: 8px 0;
}

.navLinkMobile {
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navLinkMobileCurrent {
  width: 30px;
  height: 2px;
  background-color: var(--feature);
  margin: 8px 0;
}

.mobileNav {
  /* position: relative; */
}

.mobileNavButton {
  width: 50px;
  height: 50px;
}

.mobileNavLinks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--background-90);
  width: 200px;
  height: 100%;
  position: fixed;
  right: 0;
  transition: all 0.3s ease-out;
}

/* .mobileNavLinks > * {
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
} */

@media only screen and (max-width: 600px) {
  .logo {
    font-size: var(--fontsize-medium);
  }
}
