@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

:root {
    --primary: #000;
    --primary-90: rgba(0, 0, 0, 0.76);
    --primary-80: rgba(0, 0, 0, 0.83);

    --background: #ffffff;
    --background-90: #fffffffa;
    --background-80: #ffffffbd;
    /* disabled button text */
    --background-70: rgba(222, 222, 222, 0.51);

    --feature: #6433FE;
    --feature-90: #6433FEc1;
    /* disabled button bg */
    --feature-80: #6433FE8e;
    --feature-70: #6433FE53;

    --error: #EB0000;

    --fontsize-tiny: 10px;
    --fontsize-small: 12px;
    --fontsize-medium: 16px;
    --fontsize-large: 21px;
    --fontsize-xlarge: 28px;
    --fontsize-xxlarge: 42px;


    --fontfamily-header: 'Open Sans';
    --fontfamily-body: 'Open Sans';
  }


  body {
    font-family: var(--fontfamily-body);
  }