@import '../ui/base.css';

.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.bodyContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 40px);
}

.videoPreviewContainer{
  background-color: var(--feature);
}
.comingSoon{
  color: #FFF;
  background-color: var(--feature);
}
.comingSoon a {
  color: #FFF;
}

.videoPreview {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: auto;
}

@media screen and (max-width: 1200px) {
  .videoPreview {
    flex-direction: column;
    align-items: center;
  }
  
}

.videoPreviewFooter {
  color: #FFF;
  padding-top: 15px;
}

.loginContainer {
  padding-top: 40px;
}

.button {
  max-width: 220px;
  margin: auto;
  border-radius: 32px;
  background-color: var(--feature);
  color: var(--background);
  font-size: var(--fontsize-medium);
  font-weight: bold;
  font-family: var(--fontfamily-body);
  padding: 16px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.button:hover {
  background-color: var(--feature-90);
  color: var(--background);
}

.disabled, .disabled:hover {
  background-color: var(--feature-80);
  color: var(--background-70);
  cursor: not-allowed;
}