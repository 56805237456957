.header1 {
  font-size: var(--fontsize-xxlarge);
  font-family: var(--fontfamily-header);
  font-weight: 700;
  margin-bottom: 20px;
}

.header2 {
  font-size: var(--fontsize-xlarge);
  font-family: var(--fontfamily-header);
  font-weight: 700;
  margin-bottom: 15px;
}

.header3 {
  font-size: var(--fontsize-large);
  font-family: var(--fontfamily-header);
  font-weight: 600;
}

.header4 {
  font-size: var(--fontsize-medium);
  font-family: var(--fontfamily-header);
  font-weight: bold;
}

.textSmall {
  font-size: var(--fontsize-small);
  text-transform: uppercase;
  font-family: var(--fontfamily-body);
  /* font-weight: bold; */
}

.textTiny {
  font-size: var(--fontsize-tiny);
  text-transform: uppercase;
  font-family: var(--fontfamily-body);
  /* font-weight: bold; */
}


.section {
  text-align: center;
  /* display: flex; */
  /* justify-content: center; */
  width: 100%;
  background-color: var(--background);
  padding: 100px 0;
}
.sectionMiddle {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--background);
  padding: 0 0 30px 0;
}

.sectionFeature {
  background-color: var(--feature);
  color: var(--background);
}
.vertical {
  display: flex;
  flex-direction: column;
}

.horizontal {
  display: flex;
  flex-direction: row;
}

.flexCentered {
  justify-content: center;
  align-items: center;
}

.flexSpaced {
  justify-content: space-between;
}

.sectionInner {
  max-width: 800px;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.textCentered {
  text-align: center;
}


.tinyPadding {
  padding-bottom: 4px;
}

.smallPadding {
  padding-bottom: 8px;
}

.mediumPadding {
  padding-bottom: 16px;
}

.largePadding {
  padding-bottom: 32px;
}

.xlargePadding {
  padding-bottom: 56px;
}


.xxlargePadding {
  padding-bottom: 100px;
}


.input {
  background-color: var(--background-70);
  color: var(--primary);
  border-radius: 32px;
  font-size: var(--fontsize-medium);
  font-family: var(--fontfamily-body);
  padding: 16px 24px;
  cursor: caret;
  /* caret-color: var(--feature); */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  outline: none;
  border: none;
  box-sizing: border-box;
}

.textError {
  color: var(--error);
}

.inputError {
  border: 1px solid var(--error);
}

@media only screen and (max-width: 600px) {
  .section {
    padding: 100px 0;
  }
  .sectionMiddle {
    padding: 0 0 100px 0;
  }
  .horizontal {
    flex-direction: column;
  }

  .horizontal.noMobile {
    flex-direction: row;
  }

  .vertical {
    align-items: center;
  }
}
