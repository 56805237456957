
.header {
  font-size: 50px;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;
  min-height: 100%;
}

