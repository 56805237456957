.container {
  padding: 15px;
  margin: 15px;
  text-align: left;
  border: 2px solid #000;
  border-radius: 5px;
  width: 350px;
  background-color: #FFF;
}

.header {
  text-align: center;
}

.content {
  display: flex;
  align-items: center;
}

.thumbnail {
  width: 150px;
  height: auto;
  margin-right: 20px;
}

.countItem {
  margin-bottom: 10px;
  
}

.countNumber {
  font-size: var(--fontsize-xxlarge);
}

.footer {
  padding-top: 10px;
}