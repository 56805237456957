.container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}
.terms {
    padding: 30px;
    font-family: var(--fontfamily-body);

}

.terms h1 {
  font-size: var(--fontsize-xxlarge);
  font-family: var(--fontfamily-header);
  font-weight: 700;
    margin-top: 1.3em;
    margin-bottom: 0.5em;
}

.terms h2 {
  font-size: var(--fontsize-xlarge);
  font-family: var(--fontfamily-header);
  font-weight: 700;
  margin-top: 1.3em;
  margin-bottom: 0.5em;
}

.terms h3 {
  font-size: var(--fontsize-large);
  font-family: var(--fontfamily-header);
  font-weight: 600;
  margin-bottom: 0.5em;
}

.terms h4 {
  font-size: var(--fontsize-medium);
  font-family: var(--fontfamily-header);
  font-weight: bold;
}

.terms p {
    line-height: 1.2rem;
    margin-bottom: 1em;
}
.terms strong, .terms b {
    font-weight: 600;
}
.terms a {
    color: var(--feature);
}
.terms table {
    border-spacing: 0;
    border: 1px solid #000;
    margin-bottom: 0.5em;
}
.terms td, .terms th {
    padding: 10px;
    border: 1px solid #000;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
}
